import { PeerAverageResponse, StudentExamTakenTypes } from 'api/analytics/types';

interface Props {
  data?: StudentExamTakenTypes;
  peerData?: PeerAverageResponse;
  avgPassingPercentage?: number;
  quiz?: Boolean;
}

export const PerformanceSummary = ({ data, peerData, avgPassingPercentage, quiz }: Props) => {
  if (!data) return null;

  const value = Math.round((data.totalQuestionsCorrect / data.totalQuestionsAnswered) * 100) || 0;
  const peerValue = peerData ? Math.round((peerData.totalQuestionsCorrect / peerData.totalQuestionsAnswered) * 100) : 0;

  return (
    <div className="paper">
      <div className="p-4 border-b border-tertiary">Performance Summary</div>
      <div className="grid grid-cols-3 p-4 gap-6">
        <div>
          <div className="flex items-center gap-3 mb-3">
            <p className="text-sm">
              Number of correct Answers: <span className="font-medium">{data.totalQuestionsCorrect}</span> of{' '}
              <span className="font-medium">{data.totalQuestionsAnswered}</span>
            </p>
          </div>
          <div className="h-7 rounded-full bg-secondary relative w-full">
            <div className="h-full rounded-full bg-success-100" style={{ width: `${value}%` }} />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-sm font-medium">
              Overall Score {value}% Correct
            </div>
          </div>
        </div>
        {(quiz!=true && avgPassingPercentage) && 
        <div>
          <div className="flex items-center gap-3 mb-3">
            <p className="text-sm">Average Passing requirement</p>
          </div>
          <div className="h-7 rounded-full bg-secondary relative w-full">
            <div className="h-full rounded-full bg-success-100" style={{ width: `${avgPassingPercentage}%` }} />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-sm font-medium">
              Avg min requirement: {avgPassingPercentage}%
            </div>
          </div>
        </div>
        }
        {quiz!=true && 
        <div>
          <div className="flex items-center gap-3 mb-3">
            <p className="text-sm">
              Peer Performance: <span className="font-medium">{peerData?.totalQuestionsCorrect}</span> of{' '}
              <span className="font-medium">{peerData?.totalQuestionsAnswered}</span>
            </p>
          </div>
          <div className="h-7 rounded-full bg-secondary relative w-full">
            <div className="h-full rounded-full bg-success-100" style={{ width: `${peerValue}%` }} />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-sm font-medium">
              {peerValue}% rank
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  );
};
