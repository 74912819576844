import { PeerAverageResponse, StudentExamTakenTypes } from 'api/analytics/types';
import Table from 'components/ui/Table';
import { TableColumn } from 'types/table';

interface Props {
  data?: StudentExamTakenTypes;
  peerData?: PeerAverageResponse;
  isPrint?: boolean;
}

export const QuestionsBySection = ({ data, isPrint, peerData }: Props) => {
  const columns: TableColumn[] = [
    {
      key: 'name',
      name: 'Section name',
      cell: (el) => el.name
    },
    {
      key: 'correctNumber',
      name: '# Correct',
      cell: (el) => `${el.totalCorrect} of ${el.totalQuestions}`
    },
    {
      key: 'correctPercentage',
      name: '% Correct',
      cell: (el) => `${((el.totalCorrect / el.totalQuestions) * 100).toFixed(2)}%`
    },
    {
      key: 'peerCorrectNumber',
      name: '# Peer Correct',
      cell: (el) => `${el.peerTotalCorrect} of ${el.peerTotalQuestions}`
    },
    {
      key: 'peerCorrectPercentage',
      name: '% Peer Correct',
      cell: (el) => `${((el.peerTotalCorrect / el.peerTotalQuestions) * 100).toFixed(2)}%`
    }
  ];

  const sections = Object.entries(data?.questionsBySection || {}).map(([name, value]) => ({
    _id: name,
    name,
    totalCorrect: value.totalCorrect,
    totalQuestions: value.totalQuestions,
    peerTotalCorrect: peerData?.questionsBySection[name]?.totalCorrect,
    peerTotalQuestions: peerData?.questionsBySection[name]?.totalQuestions
  }));

  return (
    <div className="paper">
      <div className="p-4 border-b border-tertiary">Sections Summary</div>
      <div className="p-4">
        <Table
          isPrint
          isLoading={false}
          data={sections}
          columns={columns}
          className={`userQuestionsByCategoriesTable ${isPrint ? 'print' : ''}`}
        />
      </div>
    </div>
  );
};
