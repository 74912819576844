import Axios from 'axios';

import { getStorageItem } from 'utils/storage';

const baseURL = process.env.REACT_APP_API_URL;

export const apiCall = async () => {
  try {
    // Headers
    const headers: any = { Accept: 'application/json' };

    const TOKEN = getStorageItem('token');
    const SESSION_TOKEN = window.sessionStorage.getItem('token');

    if (SESSION_TOKEN) {
      headers.Authorization = `Bearer ${SESSION_TOKEN}`;
    } else {
      if (TOKEN) headers.Authorization = `Bearer ${TOKEN}`;
    }

    // Create axios instance
    const axios = Axios.create({ baseURL, headers });

    // Add a request interceptor
    axios.interceptors.response.use(
      (data) => Promise.resolve(data),
      ({ response }) => {
        console.error(response);
        return Promise.reject(response);
      }
    );

    return axios;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
